<template>
  <div>
    <base-table
      :render-list-to-show="this.listToShow"
      :per-page="100"
      :wrapper-filters-data="this.wrapperFiltersData"
      :columns="this.columns"
      :enable-save-csv="true"
      saveCsvUrl="/admin/statistic/byUser/downloadCsvUserStatistic"
    >
      <template v-slot:base-table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'is_checked'">
            <button v-if="props.row[props.column.field] == 0" class="btn btn-success w-100"
                    @click="reviewFeedback(props.row['id'])">
              Рассмотреть
            </button>
            <span :class="'gray'" v-if="props.row[props.column.field] == 1">{{  'Рассмотренно'}}<br></span>
          </template>
          <template v-else-if="props.column.field === 'manager'">
            <span :class="'red'" v-if="props.row[props.column.field] == null">{{'NO'}}<br></span>
            <span :class="'green'" v-else>{{ props.row[props.column.field] }}<br></span>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/baseTable.vue";

export default {
  name: 'updateCountryOrService',
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    Multiselect,
    BaseTable
  },
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Заявки на обратную связь",
        filtersUrl: "/feedback/getList",
        filtersUrlMethod: "get",
        sendFilterRequestAfterSelect: false,
        startWithRequest: true,
        items: {
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 2,
                title: 'За последний час'
              },
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
            ],
            column: 2,
          },
          allFeedbacks: {
            enable: true,
            filterKey: 'allFeedbacks',
            upperSpanText: 'Все заявки: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'active',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'allFeedbacks',
            column: 2
          },
        },
      },
      columns: [
        {
          label: 'Поле name',
          field: 'name',
          width: '11%',
        },
        {
          label: 'Контакт',
          field: 'contact',
          width: '11%',
        },
        {
          label: 'Поле text',
          field: 'text',
          width: '20%'
        },
        {
          label: 'Клиент',
          field: 'user',
          width: '15%',
        },
        {
          label: 'Рассмотрено',
          field: 'is_checked',
          width: '8%'
        },
        {
          label: 'Менеджер',
          field: 'manager',
          width: '10%'
        },
        {
          label: 'Страница',
          field: 'type_id',
          width: '10%'
        },
        {
          label: 'Куки',
          field: 'cookies',
          width: '10%'
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          user: elem?.user?.login ?  elem?.user?.login + ' (' + elem?.user?.id + ')' : 'Unauthorized user',
          name: elem['name'],
          contact: elem['contact'],
          text: elem['text'],
          cookies: elem['cookies'],
          is_checked: elem['is_checked'],
          manager: elem?.manager?.login ? elem?.manager?.login + ' (' + elem?.manager?.id + ')' : null,
          type_id: elem['type_id']['description']
        });
      }
      return data;
    },
    reviewFeedback: async function(feedbackId){
      let response = await this.sendRequest('/feedback/changeStatus','post',{feedbackId});
      if (response['status'] === 'ok') {
        this.$root.$emit('update-item', response['data']);
        await this.alertMessage(
          'Статус успешно изменен',
          'info'
        );
      }
    }
  }
}
</script>
